import BaseAction from '@/actions/base_action.js'

export default class extends BaseAction {
  execute(properties, component) {
    const spec = {
      "action": "windows/open",
      "url": properties.url,
    }
    GLib.action.execute(spec, component);
  }
}
